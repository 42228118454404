<template>
  <div>
    <h5 class="text-md-left text-center">Datos del asegurado</h5>
    <form id="formulario">
      <div class="row mt-4">
        <div class="col-md-4">
          <div class="form-group">
            <label>Nombre</label>
            <div class="position-relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="fea icon-sm icons feather feather-user"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle></svg
              ><input
                v-model="$v.form.name.$model"
                id="name"
                type="text"
                placeholder="Ingrese nombre"
                class="form-control pl-5"
                name="name"
              />
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.name.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Apellido paterno</label>
            <div class="position-relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="fea icon-sm icons feather feather-user-check"
              >
                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="8.5" cy="7" r="4"></circle>
                <polyline points="17 11 19 13 23 9"></polyline></svg
              ><input
                v-model="$v.form.lastNameFa.$model"
                name="lastNameFa"
                id="last"
                type="text"
                placeholder="Ingrese apellido paterno"
                class="form-control pl-5"
              />
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.lastNameFa.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Apellido materno</label>
            <div class="position-relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="fea icon-sm icons feather feather-user-check"
              >
                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="8.5" cy="7" r="4"></circle>
                <polyline points="17 11 19 13 23 9"></polyline></svg
              ><input
                v-model="$v.form.lastNameMa.$model"
                name="lastNameMa"
                id="last"
                type="text"
                placeholder="Ingrese apellido materno"
                class="form-control pl-5"
              />
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.lastNameMa.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Género</label>
            <div class="position-relative">
              <div>
                <b-form-radio-group>
                  <b-form-radio
                    v-model="$v.form.gender.$model"
                    aria-describedby="ariaDescribedby"
                    name="gender"
                    value="male"
                  >
                  Masculino</b-form-radio>
                  <b-form-radio
                    v-model="$v.form.gender.$model"
                    aria-describedby="ariaDescribedby"
                    name="gender"
                    value="female"
                  >
                    Femenino</b-form-radio
                  >
                </b-form-radio-group>
              </div>
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.gender.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Email</label>
            <div class="position-relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="fea icon-sm icons feather feather-mail"
              >
                <path
                  d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                ></path>
                <polyline points="22,6 12,13 2,6"></polyline></svg
              ><input
                v-model="$v.form.email.$model"
                name="email"
                id="email"
                type="email"
                placeholder="Ingrese su email"
                class="form-control pl-5"
              />
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.email.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Teléfono</label>

            <div class="position-relative">
              <input
                v-model="$v.form.phone.$model"
                name="phone"
                id="phone"
                type="text"
                minlength="10"
                v-mask="'(##) #### ####'"
                placeholder="Ingrese teléfono"
                class="form-control"
              />
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.phone.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Fecha de nacimiento</label>

            <div class="position-relative">
              <a-date-picker
                :disabled-date="disabledDate"
                v-model="$v.form.dateBirth.$model"
                :format="dateFormat"
                class="col-12 py-1"
                :locale="localeDate"
                :default-value="setDate(18)"
              />

              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.dateBirth.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Estado civil</label>
            <select
              v-model="$v.form.maritalStatus.$model"
              class="form-control form-select"
            >
              <option value="1" selected>Casado</option>
              <option value="2">Soltero</option>
              <option value="3">Divorciado</option>
              <option value="4">Union libre</option>
              <option value="5">Viudo</option>
              <option value="6">No proporciona</option>
            </select>
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.maritalStatus.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Tipo de vivienda</label>
            <select
              v-model="form.livingPlace"
              class="form-control form-select"
            >
              <option value="casa_sola" selected>Casa Sola</option>
              <option value="departamento">Departamento</option>
              <option value="condominio_horizontal">Condominio Horizontal</option>
              <option value="condominio_vertical">Condominio Vertical</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Nombre de empresa</label>

            <div class="position-relative">
              <input
                v-model="$v.form.contractor.$model"
                name="contractor"
                id="contractor"
                type="text"
                placeholder="Ingrese nombre de empresa"
                class="form-control"
              />
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.contractor.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            
            <h4 class="text-center">{{ formatter(form.secureAmount) }}</h4>

            <div class="position-relative">
              <a-slider
                :default-value="40000"
                :step="100000"
                :tip-formatter="formatter"
                :min="limitA"
                :max="limitB"
                v-model="$v.form.secureAmount.$model"
                name="secureAmount"
                id="secureAmount"
              />
<p
              class="text-center"
              v-b-tooltip.hover.top
              :title="this.query.selectedPackage == 'PROPIETARIO' || this.query.selectedPackage == 'ARRENDADOR'
                  ? form.livingPlace == 'departamento' || form.livingPlace == 'condominio_vertical' || form.livingPlace == 'condominio_horizontal'
                    ? 'Valor comercial de la vivienda'
                    : 'Valor de la construcción sin el terreno'
                  : ''
                  "
            >
              <b>{{ checkLabelMount() }}</b>
            </p>
              <!-- <input
                v-model="$v.form.secureAmount.$model"
                name="secureAmount"
                id="secureAmount"
                type="text"
                placeholder="Ej. $ 5.000.000 MXN"
                class="form-control"
              /> -->
              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.secureAmount.$error }"
              >
                <span>Proporcione un valor válido</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="rounded shadow mt-4">
      <div class="p-4 text-center">
        <h6 class="mb-0">
          Asegúrese de ingresar la información correcta para la cotización del seguro
        </h6>
        <div class="mt-4">
          <a-button
            v-bind:disabled="disabled"
            @click="addInsuredDoc()"
            class="btn btn-primary"
            :loading="loading"
          >
            <span
              v-if="disabled === true"
              class="mr-3 spinner-grow spinner-grow-sm mr-1"
              role="status"
              aria-hidden="true"
            ></span>

            Continuar para cotizar
            <arrow-right-icon size="1.5x" class="custom-class"></arrow-right-icon>
          </a-button>
          <b-toast id="my-toast-insured" variant="primary" solid>
            <template #toast-title>
              <div class="d-flex flex-grow-1 align-items-baseline">
                <small class="text-muted font-weight-bold mr-2"></small>
              </div>
            </template>
            <div class="container text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check-circle mt-5"
              >
                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                <polyline points="22 4 12 14.01 9 11.01"></polyline>
              </svg>
              <h4 class="mb-5 mt-3 font-weight-light">
                Paso completado <br />
                Exitosamente !
              </h4>
            </div>
          </b-toast>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, numeric } from "vuelidate/lib/validators";
import { firestore } from "@/services/firebase.service";
import moment from "moment";

import locale from "ant-design-vue/es/date-picker/locale/es_ES";
import { ArrowRightCircleIcon, ArrowRightIcon } from "vue-feather-icons";
import { getPlans } from "@/services/costco.service";

import "moment/locale/es";
import { log } from "console";


const isTrue = (value) => value === true;

export default {
  components: { ArrowRightCircleIcon, ArrowRightIcon },
  mixins: [validationMixin],
  data() {
    return {
      disabled: false,

      dateFormat: "DD/MM/YYYY",

      localeDate: locale,

      limitA: 0,
      limitB: 5000000,

      loading: false,
      isDownloading: false,

      //  dateNow: moment().subtract('years', 24),

      form: {
        name: "",
        lastNameFa: "",
        lastNameMa: "",
        gender: "",
        email: "",
        secureAmount: "",
        contractor: "",
        maritalStatus: "",
        livingPlace: "casa_sola",
        phone: "",
        // addressStreet: "",
        // addressNE: "",
        // addressNI: "",
        // country: "",
        // suburb: "",
        // CP: "",
        // municipality: "",
        // state: "",
        dateBirth: "",
        // avg: "",
        // switch1: false,
        // switch2: false,
        // switch3: false,
      },
    };
  },

  computed: {
    query() {
      return this.$route.query;
    },
  },

  mounted() {
    this.checkLimit();
  },

  validations: {
    form: {
      name: { required },
      lastNameFa: { required },
      lastNameMa: { required },
      gender: { required },
      email: { required, email },
      contractor: { required },
      maritalStatus: { required },
      // livingPlace: { required },
      phone: { required },
      secureAmount: { required, numeric },
      // addressNE: { required },
      // addressNI: { required },
      // country: { required },
      // suburb: { required },
      // CP: { required, numeric },
      // municipality: { required },
      // state: { required },
      dateBirth: { required },
      // avg: { required, numeric },
    },
  },

  methods: {
    moment,
    disabledDate(current) {
      // Can not select days before today and today
      return current > moment().subtract("years", 18).endOf("day");
    },
    setDate(age){ 
      return moment().subtract(age, 'years')
    },
    checkLabelMount() {
      switch (this.query.selectedPackage) {
        case "ARRENDATARIO":
          return "Valor de los contenidos de la vivienda.";
        default:
          return "Valor de la vivienda a asegurar.";
      }
    },

    checkLimit() {
      switch (this.query.selectedPackage) {
        case "PROPIETARIO": {
          this.limitA = 500000;
          this.limitB = 15000000;
          break;
        }
        case "ARRENDADOR": {
          this.limitA = 500000;
          this.limitB = 15000000;
          break;
        }
        case "ARRENDATARIO": {
          this.limitA = 500000;
          this.limitB = 4000000;
          break;
        }
      }
    },
    // nextStep() {
    //   this.$router.push({
    //     name: "Insured",
    //   });
    // },

    formatter(value) {
      // return `${value}%`;
      return `$ ${new Intl.NumberFormat("es-MX", {
        currency: "MXN",
        maximumFractionDigits: 10,
      }).format(value)} MXN`;
    },

    async addInsuredDoc() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) return;

        localStorage.setItem("formStep1", JSON.stringify(this.form));

        this.loading = true;

        const dataZone = localStorage.getItem("zones") || null;
        
        const zones = JSON.parse(dataZone);

        localStorage.setItem("secureAmount", this.form.secureAmount);

        const dataCustomer = {
          ...this.form,
        };

        
        
  
       

        const data = await getPlans(
          this.query.selectedPackage,
          this.form.secureAmount,
          zones[0].zones.zona_hidro,//zones.map((r)=>{return r.zones.zona_hidro}),
          zones[0].zones.zona_sismica,//zones.map((r)=>{return r.zones.zona_sismica}),
          dataCustomer
        );

        
       

        this.loading = false;

        if (!data.status) {
          this.$gtag.event("info_error", {
            event_category: "quote",
            event_label: "Information error",
            value: 1,
          });

          return this.$swal("Oops!", data.message, "error");
        } else {
          this.$gtag.event("info_completed", {
            event_category: "quote",
            event_label: "Information completed",
            value: 1,
          });

          localStorage.setItem("planData", JSON.stringify(data.data));
          localStorage.setItem('quoteId', data.data.quoteId);
          //Evento que dispara el evento next >> Remover en producción
          return this.$emit("next");
        }
      } catch (error) {
        this.loading = false;
        return this.$swal(
          "Oops!",
          "Fallo de cotizador, favor comunicarse con AIG.",
          "error"
        );
      }

      // console.log();
      // // Evento que dispara el evento next >> Remover en producción
      // // return this.$emit("next");

      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) return;
      // try {
      //   this.disabled = !this.disabled;
      //   await firestore.collection("insured").add({
      //     name: this.form.name,
      //     lastNameFa: this.form.lastNameFa,
      //     lastNameMa: this.form.lastNameMa,
      //     email: this.form.email,
      //     contractor: this.form.contractor,
      //     livingPlace: this.form.livingPlace,
      //     phone: this.form.phone,
      //     addressStreet: this.form.addressStreet,
      //     addressNE: this.form.addressNE,
      //     addressNI: this.form.addressNI,
      //     country: this.form.country,
      //     suburb: this.form.suburb,
      //     CP: this.form.CP,
      //     municipality: this.form.municipality,
      //     state: this.form.state,
      //     RFC: this.form.RFC,
      //     avg: this.form.avg,
      //   });
      //   this.$bvToast.show("my-toast-insured");
      // } catch (e) {
      //   console.log("no se pudo ejecutar la accion");
      // }
      // this.disabled = !this.disabled;
    },
  },
};
</script>
